<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb @on-saveContinue="onFormSubmit(true)" />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <b-card>
                <b-card-body class="p-0">
                  <b-card-title class="mb-5">
                    Additional content section for quotes, highlights, or other
                    content you would like to feature prominently on your site.
                    <span class="font-weight-normal">(Optional)</span>
                  </b-card-title>
                  <b-form @submit.prevent="onFormSubmit">
                    <h5>Title Text</h5>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['far', 'star']" />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="8"
                          order="3"
                          order-lg="2"
                          class="text"
                        >
                          This title can act as a pull quote or call-out on your
                          website.
                        </b-col>
                        <b-col
                          cols="9"
                          lg="3"
                          order="2"
                          order-lg="3"
                          class="button"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            v-b-modal.modal-theme-guide
                            @click="featureTitleModal()"
                          >
                            View Example
                            <font-awesome-icon
                              :icon="['far', 'window-maximize']"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-alert :show="!!errorMessage" variant="danger">{{
                      errorMessage
                    }}</b-alert>
                    <b-form-group>
                      <ValidationProvider
                        name="Feature Title"
                        v-slot="validationContext"
                      >
                        <b-form-input
                          id="input-title"
                          v-model="candidateModel.featureTitle"
                          :state="getValidationState(validationContext)"
                          placeholder="Ex: Why I'm running"
                        ></b-form-input>
                        <b-form-invalid-feedback>{{
                          validationContext.errors[0]
                        }}</b-form-invalid-feedback>
                      </ValidationProvider>
                    </b-form-group>
                    <h5 class="mt-5">Content</h5>
                    <div class="guide-tip">
                      <b-row align-v="center">
                        <b-col cols="3" lg="1" class="icon">
                          <font-awesome-icon :icon="['far', 'star']" />
                        </b-col>
                        <b-col
                          cols="12"
                          lg="8"
                          order="3"
                          order-lg="2"
                          class="text"
                        >
                          This could be a key point of your campaign, an
                          endorsement, or any text that you would like to give
                          importance.
                        </b-col>
                        <b-col
                          cols="9"
                          lg="3"
                          order="2"
                          order-lg="3"
                          class="button"
                        >
                          <b-button
                            variant="outline-secondary"
                            size="sm"
                            v-b-modal.modal-theme-guide
                            @click="featureTextModal()"
                          >
                            View Example
                            <font-awesome-icon
                              :icon="['far', 'window-maximize']"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                    <b-form-group>
                      <vue-editor
                        v-model="candidateModel.featureText"
                        @text-change="onContentChange"
                        :editorOptions="editorOptions"
                        placeholder="You can enter a block of text here with line breaks."
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="parseInt(candidateModel.profileTheme) === 4"
                    >
                      <h5 class="mt-4">Image</h5>
                      <div class="guide-tip">
                        <b-row align-v="center">
                          <b-col cols="3" lg="1" class="icon">
                            <font-awesome-icon :icon="['fas', 'image']" />
                          </b-col>
                          <b-col
                            cols="12"
                            lg="8"
                            order="3"
                            order-lg="2"
                            class="text"
                          >
                            This image will show next to the text above. Click
                            the
                            <span class="font-weight-bold">View Example</span>
                            button to the right to see what this can look like.
                          </b-col>
                          <b-col
                            cols="9"
                            lg="3"
                            order="2"
                            order-lg="3"
                            class="button"
                          >
                            <b-button
                              variant="outline-secondary"
                              size="sm"
                              v-b-modal.modal-theme-guide
                              @click="featureImageModal()"
                            >
                              View Example
                              <font-awesome-icon
                                :icon="['far', 'window-maximize']"
                              />
                            </b-button>
                          </b-col>
                        </b-row>
                      </div>
                      <b-row>
                        <b-col
                          :lg="hasFeaturePhoto ? 7 : 12"
                          :class="hasFeaturePhoto ? `pr-0` : ``"
                        >
                          <ValidationProvider name="Feature Image">
                            <b-form-file
                              v-model="featureShot"
                              accept="image/*"
                              placeholder="Select a photo"
                              @change.stop="imagePreview"
                            ></b-form-file>
                          </ValidationProvider>
                        </b-col>
                        <b-col lg="5">
                          <b-button
                            v-if="hasFeaturePhoto"
                            @click="editImage"
                            variant="outline-secondary"
                            >Edit <font-awesome-icon :icon="['fas', 'edit']"
                          /></b-button>
                          <b-button
                            class="ml-2"
                            variant="outline-danger"
                            v-if="hasFeaturePhoto"
                            @click="resetImages"
                            >Remove</b-button
                          >
                        </b-col>
                      </b-row>
                    </b-form-group>
                    <b-row>
                      <b-col lg="8">
                        <!-- <cropper
                          ref="cropper"
                          class="cropper"
                          :src="candidateModel.featurePhotoPath"
                          :stencil-props="{
                            aspectRatio: 4 / 5,
                            movable: true,
                            resizable: true,
                          }"
                        /> -->
                        <b-button
                          v-show="false"
                          ref="submit"
                          type="submit"
                          variant="primary"
                          >Submit</b-button
                        >
                        <b-img
                          v-if="imageSrc"
                          :src="imageSrc"
                          fluid
                          alt="Featureshot Photo"
                        ></b-img>
                      </b-col>
                    </b-row>
                  </b-form>
                </b-card-body>
              </b-card>
              <AdminFooterButton
                @on-save="onSave"
                @on-skip="onSkip"
                @on-back="onBack"
                @on-preview="onPreview"
                :hideSkipButton="false"
                :saving="saving"
                :invalid="invalid"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <info-dialog
      :dialog="dialog"
      :title="`Feature Image`"
      :text="`This image only shows if you have Template 4 active theme`"
      @hide="dialog = false"
    ></info-dialog>
    <ModalThemeGuide
      :theme-id="infoModalId"
      :template-id="candidateModel.profileTheme"
    />
    <b-modal id="modal-crop-image" title="Crop Image" size="lg" hide-footer>
      <b-row>
        <b-col>
          <cropper
            ref="cropper"
            class="cropper"
            :src="imageSrc"
            :stencil-props="{
              aspectRatio: 2 / 3,
              movable: true,
              resizable: true,
            }"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col class="text-left">
          <b-button type="button" variant="secondary" @click="cancelCrop"
            >Cancel</b-button
          >
        </b-col>
        <b-col class="text-right">
          <b-button type="button" variant="primary" @click="cropImage"
            >Crop</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { VueEditor } from "vue2-editor";
import InfoDialog from "@/components/common/InfoDialog";
import DirtyFlagMixin from "@/mixins/dirtyflag.mixin";
import ImageMixin from "@/mixins/image.mixin";
import CandidateMixin from "@/mixins/candidate.mixin";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
export default {
  name: "AboutFeatureText",
  mixins: [DirtyFlagMixin, ImageMixin, CandidateMixin],
  components: {
    InfoDialog,
    VueEditor,
    Cropper,
  },
  data() {
    return {
      dialog: false,
      previewSrc: null,
      featureShot: null,
      infoModalId: null,
      editorOptions: {
        modules: {
          toolbar: false,
        },
      },
      imageSrc: null,
    };
  },
  computed: {
    hasFeaturePhoto() {
      return this.candidateModel.featurePhotoPath || this.imageSrc;
    },
  },
  watch: {
    "candidateModel.featurePhotoPath": {
      deep: true,
      handler() {
        this.imageSrc = this.candidateModel.featurePhotoPath;
      },
    },
  },
  methods: {
    editImage() {
      this.$bvModal.show("modal-crop-image");
    },
    cancelCrop() {
      this.$bvModal.hide("modal-crop-image");
    },
    cropImage() {
      const uploadedFile = this.featureShot;
      const { canvas } = this.$refs.cropper.getResult();
      if (canvas) {
        canvas.toBlob((blob) => {
          if (uploadedFile) {
            const file = new File([blob], uploadedFile.name, {
              type: uploadedFile.type,
              lastModified: new Date().getTime(),
            });
            this.featureShot = file;
          } else {
            var existingImageUrl = this.candidateModel.featurePhotoPath;
            var filename = existingImageUrl.substring(
              existingImageUrl.lastIndexOf("/") + 1
            );
            const file = new File([blob], filename);
            this.featureShot = file;
          }
          const url = URL.createObjectURL(blob);
          this.candidateModel.featurePhotoPath = url;
          this.imageSrc = url;
        });
      }
      this.$bvModal.hide("modal-crop-image");
    },
    onContentChange() {
      if (
        this.candidateModel.featureText.localeCompare(
          this.candidateTemp.featureText
        ) !== 0
      )
        this.$refs.observer.flags.dirty = true;
    },
    featureTextModal() {
      this.infoModalId = "featureText";
    },
    featureTitleModal() {
      this.infoModalId = "featureTitle";
    },
    featureImageModal() {
      this.infoModalId = "featureImage";
    },
    resetImages() {
      this.candidateModel.featurePhotoPath = null;
      this.previewSrc = null;
      this.featureShot = null;
      this.imageSrc = null;
    },
    imagePreview(e) {
      const { files } = e.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.candidateModel.featurePhotoPath) {
          URL.revokeObjectURL(this.candidateModel.featurePhotoPath);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        this.candidateModel.featurePhotoPath = blob;
        this.imageSrc = blob;
      }
    },
    onPreview() {},
    onBack() {
      this.$router.push("open-content");
    },
    onSkip() {
      if (this.$store.state.candidates.candidate.medias.length > 0)
        this.$router.push("manage-media");
      else this.$router.push("add-media-block");
    },
    onSave() {
      this.$refs.submit.click();
    },
    onFormSubmit(fromBreadcrumb) {
      let payload = {
        userId: this.$route.params.id,
        title: this.candidateModel.featureTitle,
        text: this.candidateModel.featureText,
        featureImage: this.featureShot,
        featureImageUrl: this.candidateModel.featurePhotoPath,
      };

      this.isSubmitting = true;
      this.saving = true;

      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.candidateService
            .SaveFeatureTextAsync(payload)
            .then((res) => {
              this.$store.commit("candidates/SET_CANDIDATE", res.data);
              setTimeout(() => {
                this.saving = false;
                if (fromBreadcrumb != true) {
                  if (this.candidateModel.medias.length > 0)
                    this.$router.push("manage-media");
                  else this.$router.push("add-media-block");
                }
              }, 500);
            })
            .catch((e) => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";
::v-deep .quillWrapper #quill-container {
  height: 500px !important;
}

.admin-wizard {
  .card-body {
    h5 {
      font-family: $montserrat;
      font-weight: 600;
    }
  }
}
</style>
