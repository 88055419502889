export default {
  data: () => ({
    previewSrc: null
  }),
  methods: {
    imagePreview(e) {
      let reader = new FileReader();
      reader.onload = () => {
        this.previewSrc = reader.result;
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }
};
