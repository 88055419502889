<template>
  <b-modal
    v-model="vDialog"
    :title="vTitle"
    size="lg"
    scrollable
    @hidden="$emit(`hide`)"
    :hide-footer="true"
  >
    {{ vText }}
  </b-modal>
</template>

<script>
export default {
  name: "info-dialog",
  data: () => ({
    vDialog: false,
    vTitle: null,
    vText: null
  }),
  props: {
    dialog: {
      type: Boolean,
      required: true,
      default: () => {
        return false;
      }
    },
    title: {
      type: String,
      required: true,
      default: () => {
        return `Info dialog Title`;
      }
    },
    text: {
      type: String,
      required: true,
      default: () => {
        return `Info dialog content`;
      }
    }
  },
  watch: {
    dialog() {
      this.vDialog = this.dialog;
      this.vTitle = this.title;
      this.vText = this.text;
    }
  }
};
</script>
